import "typeface-open-sans";
import FontFaceObserver from "fontfaceobserver";
import PropTypes from "prop-types";
import React from "react";
import { graphql, StaticQuery } from "gatsby";

import {
  getScreenWidth,
  initNoFocusOutlineAccessibilityHandler,
  timeoutThrottlerHandler
} from "../utils/helpers";
import Footer from "../components/Footer/";
import Header from "../components/Header";
import themeObjectFromYaml from "../theme/theme.yaml";

export const ThemeContext = React.createContext(null);
export const ScreenWidthContext = React.createContext(0);
export const FontLoadedContext = React.createContext(false);

class Layout extends React.Component {
  constructor() {
    super();

    this.state = {
      font400PrimaryLoaded: false,
      font400SecondaryLoaded: false,
      font600PrimaryLoaded: false,
      font600SecondaryLoaded: false,
      screenWidth: 0,
      headerMinimized: false,
      theme: themeObjectFromYaml
    };

    if (typeof window !== `undefined`) {
      this.loadFont("font400Primary", "Open Sans", 400);
      this.loadFont("font400Secondary", "Roboto", 400);
      this.loadFont("font600Primary", "Open Sans", 600);
      this.loadFont("font600Secondary", "Roboto", 600);
    }
  }

  timeouts = {};

  componentDidMount() {
    this.setState({
      screenWidth: getScreenWidth()
    });
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.resizeThrottler, false);
    }

    initNoFocusOutlineAccessibilityHandler();
  }

  resizeThrottler = () => {
    return timeoutThrottlerHandler(this.timeouts, "resize", 100, this.resizeHandler);
  };

  resizeHandler = () => {
    this.setState({ screenWidth: getScreenWidth() });
  };

  isHomePage = () => {
    if (this.props.location.pathname === "/") {
      return true;
    }

    return false;
  };

  loadFont = (name, family, weight) => {
    const font = new FontFaceObserver(family, {
      weight: weight
    });

    font.load(null, 10000).then(
      () => {
        console.log(`${name} is available`);
        this.setState({ [`${name}Loaded`]: true });
      },
      () => {
        console.log(`${name} is not available`);
      }
    );
  };

  render() {
    return (
      <StaticQuery
        query={graphql`
          query LayoutgQuery {
            #            pages: allMarkdownRemark(
            #              filter: { fileAbsolutePath: { regex: "//pages//" }, fields: { prefix: { regex: "/^d+$/" } } } this regex is not correct, look in annotations
            #              sort: { fields: [fields___prefix], order: ASC }
            #            ) {
            #              edges {
            #                node {
            #                  fields {
            #                    slug
            #                    prefix
            #                  }
            #                  frontmatter {
            #                    title
            #                    menuTitle
            #                  }
            #                }
            #              }
            #            }
            footnote: markdownRemark(fileAbsolutePath: { regex: "/footnote/" }) {
              id
              html
            }
          }
        `}
        render={data => {
          const { children } = this.props;
          const {
            footnote: { html: footnoteHTML }
            // pages: { edges: pages }
          } = data;

          return (
            <ThemeContext.Provider value={this.state.theme}>
              <FontLoadedContext.Provider value={this.state.font400PrimaryLoaded}>
                <ScreenWidthContext.Provider value={this.state.screenWidth}>
                  <React.Fragment>
                    <div className="content">
                      <Header
                        path={this.props.location.pathname}
                        // pages={pages}
                        pages={[]}
                        theme={this.state.theme}
                      />
                      <main>{children}</main>
                      <Footer
                        html={footnoteHTML}
                        path={this.props.location.pathname}
                        theme={this.state.theme}
                      />
                    </div>

                    {/* language=SCSS */}
                    <style jsx>{`
                      .content {
                        position: relative;
                        min-height: 100vh;
                      }
                      main {
                        padding-bottom: ${this.state.theme.footer.height};
                      }

                      @below desktop {
                        main {
                          padding-bottom: ${`calc(${this.state.theme.footer.height})`};
                        }
                      }
                    `}</style>

                    {/* language=SCSS */}
                    <style jsx global>{`
                      html {
                        box-sizing: border-box;
                        height: 100%;
                      }
                      *,
                      *:after,
                      *:before {
                        box-sizing: inherit;
                        margin: 0;
                        padding: 0;
                      }
                      body {
                        font-family: ${this.state.font400PrimaryLoaded
                          ? "'Open Sans', sans-serif;"
                          : "Arial, sans-serif;"};
                        min-height: 100%;
                      }
                      h1,
                      h2,
                      h3 {
                        font-family: ${this.state.font400SecondaryLoaded
                          ? "'Roboto', sans-serif;"
                          : "Arial, sans-serif;"};
                        font-weight: ${this.state.font600SecondaryLoaded ? 600 : 400};
                        line-height: 1.1;
                        letter-spacing: 0;
                        margin: 0;
                      }
                      h1 {
                        letter-spacing: 0;
                      }
                      p {
                        margin: 0;
                      }
                      strong {
                        font-weight: ${this.state.font400PrimaryLoaded ? 600 : 400};
                      }
                      a {
                        text-decoration: none;
                        color: #666;
                      }
                      main {
                        width: auto;
                        display: block;
                      }

                      .no-focus-outline a:focus,
                      .no-focus-outline button:focus {
                        outline: none;
                      }

                      #iubenda-cs-banner * {
                        font-family: ${this.state.font400PrimaryLoaded
                          ? "'Open Sans', sans-serif "
                          : "Arial, sans-serif "} !important;
                      }

                      @below desktop {
                        body {
                          font-size: 14px;
                        }
                      }
                    `}</style>
                  </React.Fragment>
                </ScreenWidthContext.Provider>
              </FontLoadedContext.Provider>
            </ThemeContext.Provider>
          );
        }}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default Layout;

//eslint-disable-next-line no-undef
/*
export const postQuery = graphql`
  query LayoutQuery {
    pages: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//pages//" }, fields: { prefix: { regex: "/^\\d+$/" } } }
      sort: { fields: [fields___prefix], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
            prefix
          }
          frontmatter {
            title
            menuTitle
          }
        }
      }
    }
    footnote: markdownRemark(fileAbsolutePath: { regex: "/footnote/" }) {
      id
      html
    }
  }
`;

*/
