module.exports = {
  siteTitle: "Hardtsolutions", // <title>
  shortSiteTitle: "HARDTSOLUTIONS", // <title> ending for posts and pages
  siteDescription: "Software engineering",
  siteUrl: "https://www.hardtsolutions.de",
  pathPrefix: "",
  siteImage: "https://www.hardtsolutions.de/preview.jpg",
  siteLanguage: "de",
  // author
  authorName: "Dimitrij Bernhardt",
  authorTwitterAccount: "dbrnhrdt",
  // info
  headerTitle: "HARDTSOLUTIONS",
  headerSubTitle: "Software Engineering & Design",
  // manifest.json
  manifestName: "HARDTSOLUTIONS - Software Engineering",
  manifestShortName: "HARDTSOLUTIONS", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",
  // social
  authorSocialLinks: [
    { name: "github", url: "https://github.com/dbrnhrdt" },
    { name: "twitter", url: "https://twitter.com/dbrnhrdt" },
    { name: "facebook", url: "http://facebook.com/dbrnhrdt" }
  ]
};
