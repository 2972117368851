module.exports = [{
      plugin: require('/app/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/app/src/layouts/index.js"},
    },{
      plugin: require('/app/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/app/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/app/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-109337169-1"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":false}},
    },{
      plugin: require('/app/node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"consentOnScroll":false,"consentOnDocument":true,"lang":"de","siteId":1595116,"cookiePolicyId":35869220,"banner":{"position":"float-bottom-right","textColor":"#fff","backgroundColor":"#182138","fontSizeBody":"14px","fontSizeCloseButton":"30px","prependOnBody":"true"}}},
    },{
      plugin: require('/app/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
