import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Footer = props => {
  const { html, theme, path } = props;

  function isContactPage() {
    return path.startsWith("/contact");
  }

  return (
    <React.Fragment>
      <footer className={"footer"}>
        <div className="copyright" dangerouslySetInnerHTML={{ __html: html }} />
        <div className="terms">
          <Link to={"/privacy"}>Datenschutz</Link>
          {isContactPage() && (
            <React.Fragment>
              {" | "} <Link to={"/terms"}>Impressum</Link>
            </React.Fragment>
          )}
        </div>
      </footer>

      {/* language=SCSS */}
      <style jsx>{`
        .footer {
          position: absolute;
          bottom: 0;
          width: 100%;
        }

        .terms {
          text-align: center;
          padding: ${theme.space.inset.default};
          height: ${theme.footer.height};
        }

        .copyright {
          :global(ul) {
            list-style: none;
            text-align: center;
            padding: 0;

            :global(li) {
              color: ${theme.color.neutral.gray.k};
              font-size: ${theme.font.size.xxs};
              padding: 0 ${theme.space.s};
              position: relative;
              display: inline-block;

              :global(a) {
                text-decoration: underline;
              }

              &::after {
                content: "•";
                position: absolute;
                right: ${`calc(${theme.space.xs} * -1)`};
              }
              &:last-child::after {
                content: "";
              }
            }
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Footer.propTypes = {
  html: PropTypes.string,
  theme: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired
};

export default Footer;
