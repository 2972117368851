import React from "react";
import PropTypes from "prop-types";

const HamburgerButton = props => {
  const { isActive, isInverted, onClick, isVisible = true } = props;

  return (
    <React.Fragment>
      <button
        className={`hamburger hamburger--elastic ${isActive ? "is-active" : ""} ${
          isInverted ? "inverted" : ""
        } ${!isVisible ? "hidden" : ""}`}
        type="button"
        onClick={onClick}
        aria-label={"Mobile Menu"}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>

      {/* language=SCSS */}
      <style jsx>
        {`
          .hidden {
            display: none;
          }
        `}
      </style>
    </React.Fragment>
  );
};

HamburgerButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isInverted: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isVisible: PropTypes.bool
};

export default HamburgerButton;
