import React from "react";
import PropTypes from "prop-types";
import { FaBook, FaEnvelope, FaHome, FaSearch } from "react-icons/fa/";

import HamburgerItem from "./HamburgerItem";

import "./hamburger.css";
import "animate.css";
import HamburgerButton from "./HamburgerButton";

require("core-js/fn/array/from");

class HamburgerMenu extends React.Component {
  constructor(props) {
    super(props);
    this.itemList = React.createRef();
    this.navContainer = React.createRef();

    // const pages = props.pages.map(page => ({
    //   to: page.node.fields.slug,
    //   label: page.node.frontmatter.menuTitle
    //     ? page.node.frontmatter.menuTitle
    //     : page.node.frontmatter.title
    // }));

    this.items = [
      { to: "/", label: "Home", icon: FaHome },
      // { to: "/blog/", label: "Blog", icon: FaBook },
      // { to: "/category/", label: "Categories", icon: FaTag },
      // { to: "/search/", label: "Search", icon: FaSearch },
      //...pages,
      { to: "/contact/", label: "Kontakt", icon: FaEnvelope }
    ];
  }

  state = {
    hamburgerOpen: false
  };

  static propTypes = {
    path: PropTypes.string.isRequired,
    fixed: PropTypes.bool.isRequired,
    screenWidth: PropTypes.number.isRequired,
    fontLoaded: PropTypes.bool.isRequired,
    pages: PropTypes.array.isRequired,
    theme: PropTypes.object.isRequired
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.path !== prevProps.path && prevState.hamburgerOpen) {
      this.toggleHamburger();
    }
  }

  toggleHamburger = () => {
    this.setState(
      prevState => ({ hamburgerOpen: !prevState.hamburgerOpen }),
      () => {
        this.animateOverlayMenu(this.state.hamburgerOpen);
      }
    );
  };

  animateOverlayMenu(hamburgerOpen) {
    const navbarElement = this.navContainer.current;
    if (hamburgerOpen) {
      navbarElement.classList.remove("animated", "slideOutRight");
      navbarElement.classList.add("overlay", "animated", "slideInRight");
    } else {
      navbarElement.classList.remove("animated", "slideInRight");
      navbarElement.classList.add("animated", "slideOutRight");
    }
  }

  render() {
    const { theme, path } = this.props;
    const { hamburgerOpen } = this.state;

    return (
      <React.Fragment>
        <div className={"faster"} ref={this.navContainer}>
          <nav className={`menu`} rel="js-menu">
            <ul className="itemList" ref={this.itemList}>
              {this.items.map(item => (
                <HamburgerItem item={item} key={item.label} icon={item.icon} theme={theme} />
              ))}
            </ul>
          </nav>
          {/*<button*/}
          {/*  className={`hamburger hamburger--elastic ${hamburgerOpen ? "is-active" : ""} ${*/}
          {/*    path !== "/" && !hamburgerOpen ? "inverted" : ""*/}
          {/*  }`}*/}
          {/*  type="button"*/}
          {/*  onClick={this.toggleHamburger}*/}
          {/*>*/}
          {/*  <span className="hamburger-box">*/}
          {/*    <span className="hamburger-inner" />*/}
          {/*  </span>*/}
          {/*</button>*/}
          <HamburgerButton
            isActive={true}
            isInverted={false}
            onClick={this.toggleHamburger}
            isVisible={false}
          />
        </div>

        <HamburgerButton
          isActive={false}
          isInverted={path !== "/"}
          onClick={this.toggleHamburger}
        />

        {/* language=SCSS */}
        <style jsx>{`
          :global(.hamburger) {
            display: flex;
            position: absolute;
            top: 20px;
            right: 20px;
            //z-index: 3;
          }

          .menu {
            display: none;
          }

          /* Overlay style */
          .overlay {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: #182138;
            z-index: 2;

            :global(.hamburger) {
              display: flex;
            }

            .menu {
              display: block;
            }

            /* Menu style */
            nav {
              text-align: center;
              position: relative;
              top: 50%;
              height: 15em;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
            }

            ul {
              list-style: none;
              padding: 0;
              margin: 0 auto;
              display: inline-block;
              height: 100%;
              position: relative;
            }

            ul li a:hover,
            ul li a:focus {
              color: #f0f0f0;
            }
          }

          @below desktop {
            .menu {
            }
          }

          @from-width desktop {
            :global(.hamburger) {
              display: none;
            }

            .menu {
              border-top: none;
              background: transparent;
              display: flex;
              position: relative;
              justify-content: flex-end;
              padding-left: 50px;
              transition: none;
            }

            .itemList {
              display: flex;
              flex-wrap: wrap;
              list-style: none;
              margin: 0;
              justify-content: flex-end;
              padding: 0;
              position: relative;
              width: 100%;
            }

            .hiddenItemList {
              list-style: none;
              margin: 0;
              position: absolute;
              background: ${theme.background.color.primary};
              border: 1px solid ${theme.line.color};
              top: 48px;
              right: ${theme.space.s};
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              padding: ${theme.space.m};
              border-radius: ${theme.size.radius.small};
              border-top-right-radius: 0;

              &:after {
                content: "";
                background: ${theme.background.color.primary};
                z-index: 10;
                top: -10px;
                right: -1px;
                width: 44px;
                height: 10px;
                position: absolute;
                border-left: 1px solid ${theme.line.color};
                border-right: 1px solid ${theme.line.color};
              }

              :global(.homepage):not(.fixed) & {
                border: 1px solid transparent;
                background: color(white alpha(-10%));
                top: 50px;

                &:after {
                  top: -11px;
                  border-left: 1px solid transparent;
                  border-right: 1px solid transparent;
                  background: color(white alpha(-10%));
                }
              }

              :global(.fixed) & {
                top: 44px;
              }
            }
          }
        `}</style>
      </React.Fragment>
    );
  }
}

export default HamburgerMenu;
